import { ReactTerminal } from "react-terminal";
import background from "./back.png";
import {useEffect, useState} from "react";

const WelcomeMessage = <div><div>{`Welcome to`}</div><img width='200' src={require('./logo.png')} />{`'s site`}</div>

function App(props) {
  // Define commands here
    const [height, setHeight] = useState(0)

    useEffect(() => {
        window.visualViewport.addEventListener('resize', (event) => {
            setHeight(event.target.height)
        });
    }, []);

  const commands = {
      help: "contact me by telegram: @doganus \n or type something here: feedback your_message",
      feedback: async (message) => {
          try {
              const userInfo = await (await fetch('https://ip.nf/me.json', {method: 'get'})).json()
              await fetch(`https://disspaun.ru/form.php?message=${message + '%0A' + JSON.stringify(userInfo.ip, null, "%0A").replaceAll('{', '').replaceAll('}', '')}`)
              return 'your message has been received'
          } catch (e) {
              return 'something went wrong, try again later'
          }
      }
  };

  return (
      <div style={{backgroundColor: 'rgb(21, 21, 21)', backgroundImage: `url(${background})`}}>
          <div style={{display: "flex", height: height ? `${height}px` : '100svh', backgroundColor: 'transparent'}}>
              <div style={{flex: 1, maxWidth: '100svw', alignSelf: 'flex-end'}}>
                      <ReactTerminal
                          commands={commands}
                          showControlBar={false}
                          theme="my-custom-theme"
                          themes={{
                              "my-custom-theme": {
                                  themeBGColor: "transparent",
                                  themeToolbarColor: "#DBDBDB05",
                                  themeColor: "#FFFEFC",
                                  themePromptColor: "#a917a8"
                              }
                          }}
                          prompt={`guest@disspaun ${Math.floor(Math.random() * 10000)}  %`}
                          welcomeMessage={WelcomeMessage}
                          errorMessage={'type help for information'}
                      />
              </div>
        </div>
      </div>
  );
}

export default App
